<template>
  <div class="row">
    <div class="col-sm-12 col-md-6 pl-0">
      <div class="col-12">
        <div class="form-item pt-10">
          <div class="form-item__label">{{ $t("profile.current_pass") }}</div>
          <div class="form-item__field">
            <input
              ref="currentPass"
              v-model.trim.lazy="$v.currentPass.$model"
              :class="{ invalid: $v.currentPass.$error || invalidCurrent }"
              maxlength="32"
              type="password"
            />
            <button
              class="btn btn-empty"
              @click="toggleVisibility('currentPass')"
            >
              <svg
                v-svg
                :symbol="toggleEye ? 'view-1' : 'view-2'"
                class="icon-pass"
                role="presentation"
                size="0 0 18 16"
              ></svg>
            </button>
            <small v-if="$v.currentPass.$error" class="error">{{
              $t("min_len_pass", { number: "3" })
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-item pt-10">
          <div class="form-item__label">{{ $t("profile.new_pass") }}</div>
          <div class="form-item__field">
            <input
              ref="newPass"
              v-model.trim.lazy="$v.newPass.$model"
              :class="{ invalid: $v.newPass.$error }"
              maxlength="32"
              type="password"
            />
            <button class="btn btn-empty" @click="toggleVisibility('newPass')">
              <svg
                v-svg
                :symbol="toggleEye2 ? 'view-1' : 'view-2'"
                class="icon-pass"
                role="presentation"
                size="0 0 18 16"
              ></svg>
            </button>
            <small v-if="$v.newPass.$error" class="error">{{
              $t("min_len_pass", { number: "3" })
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-item pt-10">
          <div class="form-item__label">
            {{ $t("profile.repeat_new_pass") }}
          </div>
          <div class="form-item__field">
            <input
              ref="repeatPass"
              v-model.trim.lazy="$v.repeatPass.$model"
              :class="{ invalid: $v.repeatPass.$error }"
              maxlength="32"
              type="password"
            />
            <button
              class="btn btn-empty"
              @click="toggleVisibility('repeatPass')"
            >
              <svg
                v-svg
                :symbol="toggleEye3 ? 'view-1' : 'view-2'"
                class="icon-pass"
                role="presentation"
                size="0 0 18 16"
              ></svg>
            </button>
            <small
              v-if="!$v.repeatPass.sameAs && $v.repeatPass.$error"
              class="error"
              >{{ $t("profile.not_same_pass") }}</small
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 two-fa-verif">
      <img src="@/assets/images/icons/verification.svg" alt="" />
      <h4>{{ $t("profile.two_factor_verif") }}</h4>
      <p>{{ $t("profile.security") }}</p>
      <div class="actions">
        <button
          :disabled="!user.is_g2a_enabled"
          class="btn off"
          @click="$modal.show('two-fa-verif')"
        >
          {{ $t("profile.disable") }}
        </button>
        <button
          :disabled="user.is_g2a_enabled"
          class="btn on"
          @click="$modal.show('two-fa-verif')"
        >
          {{ $t("profile.enable") }}
        </button>
      </div>
    </div>
    <div class="col-12">
      <button class="btn _mob100" @click="save">{{ $t("save") }}</button>
    </div>
    <alert v-if="$alert.title" />
    <modal
      @closed="$modal.hide('two-fa-verif')"
      :width="650"
      name="two-fa-verif"
      height="auto"
      :scrollable="true"
      classes="verif-modal"
      :shiftY="0"
    >
      <span
        class="close"
        aria-hidden="true"
        @click="$modal.hide('two-fa-verif')"
        ><svg v-svg symbol="x" size="0 0 17 17"></svg
      ></span>
      <h2>{{ $t("profile.instruction") }}</h2>
      <div class="verif-guide" v-for="(step, index) in steps" :key="index">
        <div class="step d-flex">
          <div class="number">{{ index + 1 }}</div>
          <span v-html="step.text"></span>
        </div>
        <img v-if="step.image" :src="qrCode" alt="" />
      </div>
      <div class="auth-code">
        <input
          type="number"
          v-model="codePart1"
          placeholder="***"
          @input="checkInputLength"
          class="w-50 mt-3"
        />
        <input
          type="number"
          ref="restCode"
          v-model="codePart2"
          placeholder="***"
          @input="checkInputLength"
          class="w-50 mt-3"
        />
      </div>
      <button class="btn mt-4" @click="set2FA">{{ $t("save") }}</button>
    </modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters, mapActions } from "vuex";
import PanelService from "@/api/servicePanel";
import {
  maxLength,
  minLength,
  required,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    currentPass: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(32),
    },
    newPass: {
      minLength: minLength(3),
      maxLength: maxLength(32),
      required,
    },
    repeatPass: {
      required,
      sameAs: sameAs("newPass"),
      minLength: minLength(3),
      maxLength: maxLength(32),
    },
  },

  data() {
    return {
      currentPass: "",
      newPass: "",
      repeatPass: "",
      invalidCurrent: false,
      toggleEye: false,
      toggleEye2: false,
      toggleEye3: false,
      codePart1: "",
      codePart2: "",
      qrCode: "",
      steps: [
        {
          text: this.formatLabel("profile.verif_guide"),
        },
        {
          text: this.formatLabel("profile.verif_guide_step2"),
        },
        {
          text: this.formatLabel("profile.verif_guide_step3"),
          image: require("@/assets/images/two-verif-qr.webp"),
        },
        {
          text: this.formatLabel("profile.verif_guide_step4"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  mounted() {
    this.getQr();
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
    }),
    getQr() {
      PanelService.getQr().then((res) => {
        this.qrCode = res.data.qr_url;
      });
    },
    formatLabel(label) {
      return this.$t(label);
    },
    checkInputLength() {
      this.codePart1.length > 3
        ? (this.codePart1 = this.codePart1.slice(0, 3))
        : null;
      this.codePart2.length > 3
        ? (this.codePart2 = this.codePart2.slice(0, 3))
        : null;
      if (this.codePart1.length === 3) this.$refs.restCode.focus();
    },
    set2FA() {
      if (Boolean(this.codePart1) && Boolean(this.codePart2)) {
        const payload = this.codePart1 + this.codePart2;

        if (this.user && this.user.is_g2a_enabled) {
          PanelService.disable2FA({ code: payload }).then((res) => {
            if (res && res.status === 200) {
              this.$alert({
                type: "success",
                title: this.$t("sent_success"),
              });
              this.$modal.hide("two-fa-verif");
              this.codePart1 = "";
              this.codePart2 = "";
              this.setUser();
            } else
              this.$alert({
                title: this.$t("sent_error"),
                text: res.message,
              });
          });
          return;
        }

        PanelService.enable2FA({ code: payload }).then((res) => {
          if (res && res.status === 200) {
            this.$alert({
              type: "success",
              title: this.$t("sent_success"),
            });

            this.$modal.hide("two-fa-verif");
            this.codePart1 = "";
            this.codePart2 = "";
            this.setUser();
          } else
            this.$alert({
              title: this.$t("sent_error"),
              text: res.message,
            });
        });
      }
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const payload = {
        current_password: this.currentPass,
        new_password: this.newPass,
        repeat_password: this.repeatPass,
      };
      PanelService.updatePassword(payload).then((res) => {
        if (res && res.status === 400) {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.data.currentPassword,
          });
          if (res.data.currentPassword) {
            this.invalidCurrent = true;
          }
        } else if (res && res.status === 200) {
          this.$alert({
            type: "success",
            title: this.$t("profile.updated_password"),
          });
        }
      });
    },
    toggleVisibility(input) {
      if (input === "currentPass") {
        this.toggleEye = !this.toggleEye;
      }
      if (input === "newPass") {
        this.toggleEye2 = !this.toggleEye2;
      }
      if (input === "repeatPass") {
        this.toggleEye3 = !this.toggleEye3;
      }

      this.$refs[input].type == "password"
        ? (this.$refs[input].type = "text")
        : (this.$refs[input].type = "password");
    },
  },
};
</script>
<style>
.verif-modal {
  padding: 30px;
  top: 50px !important;
  border-radius: 20px;
}
.close {
  position: absolute;
  right: 16px;
  top: 10px;
  cursor: pointer;
}
</style>


<style lang="scss" scoped>
.form-item__field {
  input {
    padding-right: 50px;
  }
}

.two-fa-verif {
  padding: 30px;
  max-width: 49%;
  border-radius: 10px;
  background: #f6f6fb;
  text-align: center;
  height: fit-content;
  @media screen and (max-width: 768px) {
    margin: 20px auto;
    max-width: 80%;
  }
  .actions {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-top: 25px;
    .btn.off {
      background: #ab4552;
    }
    .btn.on {
      background: #37807d;
    }
  }
  h4 {
    padding-top: 20px;
  }
  p {
    color: #141414;
    max-width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    font-size: 14px;
  }
}

.dark .two-fa-verif {
  background: #434343;
  p {
    color: #dddddd;
  }
}

.btn.btn-empty {
  padding: 0 15px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  height: 45px;
  min-height: 45px;
}

.verif-guide {
  display: flex;
  flex-flow: column wrap;
  margin-top: 10px;
  .step {
    column-gap: 10px;
    align-items: flex-start;
    span {
      line-height: 1.8;
    }
    span ::v-deep a.download-link {
      color: #37807d;
      border-bottom: 1px dashed #37807d;
    }
  }
  .number {
    border-radius: 3px;
    padding: 4px 10px;
    display: block;
    height: fit-content;
    background: #eaf4f3;
    color: #37807d;
  }
  img {
    width: fit-content;
    margin: 20px 0 0 40px;
  }
}

.auth-code {
  display: flex;
  column-gap: 8px;
  input {
    text-align: center;
    width: 80px !important;
    letter-spacing: 3px;
  }
}
</style>