<template>
  <div class="col-12 payments-wrapper">
    <div class="payments-block">
      <div class="form-item align-items-center justify-content-between d-flex p-0">
        <div class="d-flex">
          <div class="payments-img" :style="`${getIcon.style}`">
            <img :src="getIcon.url" alt="" />
          </div>
          <div class="payments-header">
            <span class="payments-header__title">{{ title === "card" || title === "cardRu" || title === "cardKz" ? $formatLabel(currentPayment(title).title) : currentPayment(title).title }} {{ title === "ph" ? `(${$formatLabel("header.balance")})` : "" }}</span>
            <span class="payments-header__currency">
              {{ currentPayment(title).currency }}
            </span>
          </div>
        </div>
        <div class="form-item__field payments-input" :class="{ 'no-value': currentButtonStatus.text === 'set' }" v-if="isVerified">
          <template v-if="currentButtonStatus.text === 'save'">
            <input
              :id="title"
              :value="value"
              @input="$emit('update-value', $event.target.value)"
              :class="{ invalid: error.$invalid }"
              autocomplete="off"
              v-tooltip="{
                html: true,
                content: `
              <svg viewBox='0 0 32 32' width='17' height='17' xmlns='http://www.w3.org/2000/svg'>
                <path d='M14.4 24h3.2v-9.6h-3.2v9.6zM16 0c-8.84 0-16 7.16-16 16s7.16 16 16 16c8.84 0 16-7.16 16-16s-7.16-16-16-16zM16 28.8c-7.056 0-12.8-5.744-12.8-12.8s5.744-12.8 12.8-12.8c7.056 0 12.8 5.744 12.8 12.8s-5.744 12.8-12.8 12.8zM14.4 11.2h3.2v-3.2h-3.2v3.2z'></path>
              </svg>
              <span>${$t(`profile.notif_${title}`)}</span>`,
                show: error.$invalid,
                trigger: '',
                classes: 'tooltip-invalid',
              }"
            />
          </template>
          <template v-else-if="currentButtonStatus.text === 'set'">
            <span class="no-payments">{{ $t("profile.no_payments") }}</span>
          </template>
          <template v-else-if="currentButtonStatus.text === 'change'">
            <span :title="value" class="payments-readonly">
              {{ value }}
            </span>
          </template>
        </div>
        <div class="payments-controls" :class="{ 'payments-controls_disabled': !isVerified }">
          <button class="payments-button" @click="paymentHandler" :class="`${currentButtonStatus.text}`" :disabled="(error.$invalid && currentButtonStatus.text !== 'change') || disableButton || !isVerified">
            <svg v-svg :symbol="currentButtonStatus.icon" v-if="currentButtonStatus.icon" size="0 0 15 15" role="info"></svg>
            {{ $formatLabel(currentButtonStatus.text) }}
          </button>
        </div>
        <div class="payments-mail-verify" v-if="limitVerification && !isVerified">
          <span v-html="$t('profile.limit_payment')"> </span>
        </div>
        <div class="payments-mail-verify" v-if="!isVerified && !limitVerification">
          <img :src="require('@/assets/images/icons/send-mail.svg')" alt="send mail" />
          <span> {{ $t("profile.verify_payment") }} </span>

          <button @click="sendMail()" class="timer-button" :disabled="timer !== null">
            {{ $t("send_again") }}
            {{ timer !== null ? `(${timerFormat})` : "" }}
          </button>
        </div>
      </div>
    </div>
    <alert v-if="$alert.title" />
  </div>
</template>
<script>
import PanelService from "@/api/servicePanel";
import timer from "@/mixins/timer";

export default {
  name: "PaymentBlock",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    isVerified: {
      type: Boolean,
      default: true,
    },
    limitVerification: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      required: true,
    },
  },
  mixins: [timer],
  data() {
    return {
      changeStatus: false,
      disableButton: false,
      buttonList: {
        set: {
          text: "set",
        },
        save: {
          text: "save",
          icon: "check",
        },
        change: {
          text: "change",
          icon: "edit",
        },
      },
      paymentList: [
        {
          id: "capitalist",
          title: "Capitalist",
          currency: "USD",
        },
        {
          id: "ph",
          title: "Push.House",
          currency: "USD",
        },
        {
          id: "pay2House",
          title: "Pay2House",
          currency: "USD",
        },
        {
          id: "webmoney",
          title: "WebMoney",
          currency: "WMZ",
        },
        {
          id: "qiwi",
          title: "Qiwi",
          currency: "RUB",
        },
        {
          id: "card",
          title: "finances.card",
          currency: "USD",
        },
        {
          id: "cardRu",
          title: "finances.cardRu",
          currency: "RUB",
        },
        {
          id: "cardKz",
          title: "finances.cardKz",
          currency: "RUB",
        },
        {
          id: "yandex",
          title: "YooMoney",
          currency: "RUB",
        },
        {
          id: "usdt",
          title: "USDT(TRC20)",
          currency: "USD",
        },
        {
          id: "payoneer",
          title: "Payoneer",
          currency: "USD",
        },
      ],
      changeValue: "",
    };
  },
  methods: {
    sendMail() {
      const payload = {
        type: this.title,
        wallet: this.value,
      };
      PanelService.resendMail(payload).then((res) => {
        if (res && res.status === 200) {
          this.setTimer(this.title, 5);
          this.$emit("refresh-wallets");
        }
      });
    },
    currentPayment(id) {
      return this.paymentList.find((el) => el.id === id);
    },
    paymentHandler() {
      if (this.error.$invalid && this.currentButtonStatus.text !== "change") {
        return;
      }
      if (this.currentButtonStatus.text === "set" || this.currentButtonStatus.text === "change") {
        this.changeStatus = true;
        this.changeValue = this.value;
      } else if (this.currentButtonStatus.text === "save") {
        this.disableButton = true;
        if (this.value.length > 1 && this.value !== this.changeValue) {
          this.sendPayment();
        } else {
          this.disableButton = false;
          this.changeStatus = false;
        }
      }
    },
    sendPayment() {
      const payload = {
        type: this.title,
        wallet: this.value,
      };
      PanelService.updateWallet(payload).then((res) => {
        this.disableButton = false;
        this.changeStatus = false;
        this.$emit("refresh-wallets");
        if (res && res.status === 400) {
          this.disableButton = false;
          this.$alert({
            title: this.$t("sent_error"),
            text: res.message,
          });
        }
      });
    },
  },
  computed: {
    currentButtonStatus() {
      if (this.changeStatus) {
        return this.buttonList.save;
      } else {
        if (this.value && this.value.length > 0) {
          return this.buttonList.change;
        } else {
          return this.buttonList.set;
        }
      }
    },
    getIcon() {
      switch (this.title) {
        case "capitalist":
          return {
            style: "background: #3aa458",
            url: require("@/assets/images/icons/capitalist.svg"),
          };
        case "ph":
          return {
            style: "background: #1C1C1C",
            url: require("@/assets/images/icons/pushHouse.svg"),
          };
        case "pay2House":
          return {
            style: "background: linear-gradient(138deg, #434A58 6.17%, #161F2F 94.49%); box-shadow: 0px 13.5014px 81.00841px 0px rgba(41, 60, 97, 0.10);",
            url: require("@/assets/images/icons/pay2House.svg"),
          };
        case "webmoney":
          return {
            style: "background: #0277be",
            url: require("@/assets/images/icons/webmoney.svg"),
          };
        case "qiwi":
          return {
            style: "background: #ff8c00",
            url: require("@/assets/images/icons/qiwi.svg"),
          };
        case "card":
          return {
            style: "background: #691d79",
            url: require("@/assets/images/icons/card.svg"),
          };
        case "cardRu":
          return {
            style: "background: #1C3C79",
            url: require("@/assets/images/icons/card.svg"),
          };
        case "cardKz":
          return {
            style: "background: #1F7B7B",
            url: require("@/assets/images/icons/card.svg"),
          };
        case "yandex":
          return {
            style: "background: #8a3ffd",
            url: require("@/assets/images/icons/yoomoney.svg"),
          };
        case "usdt":
          return {
            style: "background: #26A17B",
            url: require("@/assets/images/icons/usdt.svg"),
          };
        case "payoneer":
          return {
            style: "background: #fff",
            url: require("@/assets/images/icons/payoneer.png"),
          };
        default:
          return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.payments-wrapper {
  @media (min-width: 1620px) {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.payments-block {
  border-radius: 5px;
  background: #f3f5f9;
  padding: 14px 18px;
}

.form-item {
  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
}

.payments-img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  margin-right: 12px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;

  img {
    width: 21px;
    height: 21px;
    transition: 0.3s;
  }
}

.payments-header {
  display: flex;
  flex-direction: column;
  gap: 2px 0;

  &__title {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
  }

  &__currency {
    font-size: 13px;
    line-height: 20px;
    font-weight: 300;
    text-transform: uppercase;
  }
}

.payments-input {
  margin-bottom: 15px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  overflow: hidden;

  input {
    padding: 0 15px;
    border-radius: 10px;
    background: #fff;
    border: none;
    outline: none;
    height: 37px;
    font-weight: 500;
  }

  @media (min-width: 576px) {
    margin-right: 33px;
  }

  @media (max-width: 575px) {
    &.no-value {
      display: none;
    }

    margin-top: 12px;
    order: 3;
    flex: 0 0 100%;
    max-width: 100%;

    input {
      width: 100%;
    }
  }
}

.payments-readonly {
  font-weight: 600;

  @media (min-width: 576px) {
    background: transparent;
    color: #141414;
    width: -webkit-fill-available;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 8px;
    text-align: end;
  }

  @media (max-width: 575px) {
    pointer-events: none;
    background: #e1e4ec;
    color: #141414;
    width: 100%;
    height: 37px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    padding: 0 16px;
    border-radius: 5px;
  }
}

.no-payments {
  color: #838792;
  font-size: 14px;
  text-align: end;
}

.payments-mail-verify {
  display: flex;
  gap: 8px 12px;
  align-items: center;
  justify-content: flex-end;

  span {
    color: #565762;
    font-size: 14px;
  }

  @media (min-width: 576px) {
    flex: 0 0 65%;
  }

  @media (max-width: 575px) {
    margin-top: 16px;
    flex: 0 0 100%;
    justify-content: center;
  }

  @media (max-width: 490px) {
    flex-wrap: wrap;
  }

  &::v-deep {
    .payment__link {
      color: #37807d;
      font-weight: 700;
      text-decoration: underline dotted;
      transition: 0.3s all ease;

      &:hover,
      &:focus {
        color: #3f928e;
      }
    }
  }
}

.timer-button {
  background: #37807d;
  color: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  height: 37px;
  min-width: 180px;
  transition: 0.3s all ease;

  &:hover,
  &:focus {
    background: #3f928e;
  }

  &:disabled {
    pointer-events: none;
    background: #fff;
    color: #141414;
    opacity: 0.7;
  }
}

.payments-controls {
  @media (min-width: 576px) {
    &_disabled {
      display: none;
    }
  }

  .payments-button {
    width: 131px;
    height: 37px;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    font-weight: 600;
    position: relative;

    @media (min-width: 576px) {
      margin-left: 33px;

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background: #d0d2d7;
        left: -33px;
        top: 0;
      }
    }

    @media (max-width: 575px) {
      width: 96px;
      font-size: 13px;

      svg {
        width: 13px;
        height: 13px;
      }
    }

    &.save {
      background: #37807d;
      color: #fff;

      svg {
        fill: #fff;
      }
    }

    &.change {
      background: #000109;
      color: #ffffff;

      svg {
        fill: #ffffff;
      }
    }

    &.set {
      background: #fff;
      color: #141414;

      svg {
        fill: #141414;
      }
    }

    &:disabled {
      cursor: not-allowed;
      background: #858793;
    }
  }
}
</style>
